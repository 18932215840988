<template>
  <div class="cointer">
    <div>
      <div class="navleft">
        江苏省工程建设地方标准编制系统
        <!-- <div v-for="(item,index) in mean_list" :key="item.name" @click="changeTab(index,item)"> v-if="mean_list.length>1"
          <div  v-if="!item.meta.hidden" :class="navleft_active==item.meta.active?'nlfir_active':'nlfir'"  >
            {{item.meta.name}}
          </div>
        </div> -->
        <div
          class=""
          v-if="$store.state.role[0] == 1"
          style="margin-left: 15px; font-size: 14px; margin-top: 9px"
        >
          管理版
        </div>
        <div
          class=""
          v-if="$store.state.role[0] == 3"
          style="margin-left: 15px; font-size: 14px; margin-top: 9px"
        >
          企业版
        </div>
      </div>
    </div>
    <div class="navright">
      <el-popover
        v-if="getRole() == 3"
        placement="bottom"
        width="400"
        v-model="shiwTip"
        trigger="click"
        style="margin-right: 20px; margin-top: 7px"
      >
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="提醒" name="first">
            <div
              v-if="
                tableData.findIndex(
                  (item) =>
                    item.status == 6 ||
                    item.status == 9 ||
                    item.status == 11 ||
                    item.status == 13
                ) != -1
              "
            >
              <div v-for="(item, index) in tableData" :key="index">
                <div
                  v-if="
                    item.status == 6 ||
                    item.status == 9 ||
                    item.status == 11 ||
                    item.status == 13
                  "
                  style="border-bottom: 1px solid #e4e7ed"
                >
                  状态为{{  (item.status == 6) ? "待提交初稿" : (item.status == 9) ? "待提交征求意见稿" : (item.status == 11) ? "待提交送审稿及材料" : (item.status == 13) ? "待提交报批表" : "其他状态"}}的
                  请尽快提交《
                  <router-link
                    :to="'/user_project/chugaoForqiyeDetail?id=' + item.id"
                    class="linkto"
                  >
                    <span>{{ item.proName }}</span>
                  </router-link>
                  》标准的资料，谢谢!
                </div>
              </div>
            </div>

            <div v-else>暂无信息</div>
          </el-tab-pane>
          <el-tab-pane label="" name="second"></el-tab-pane>
        </el-tabs>
        <span slot="reference" class="el-dropdown-link">
          <el-badge
            is-dot
            v-if="
              tableData.status == 6 ||
              tableData.status == 9 ||
              tableData.status == 11 ||
              tableData.status == 13
            "
          >
            <i
              class="el-icon-message-solid"
              style="color: red; font-size: 32px"
            ></i
          ></el-badge>
          <i
            v-else
            class="el-icon-message-solid"
            style="color:  red; font-size: 32px"
          ></i>
        </span>
      </el-popover>

      <el-dropdown>
        <div class="el-dropdown-link tohver">
          <div>
            <img
              v-if="
                $store.state.user.avatar != '' &&
                $store.state.user.avatar != null
              "
              :src="$store.state.user.avatar"
              alt=""
              width="40px"
              height="40px"
              style="border-radius: 50%"
            />
            <img
              v-else
              src="https://api.gbservice.cn/storage/uploads/699ab4d283acc595369836beb8082607.png"
              alt=""
              width="40px"
              height="40px"
              style="border-radius: 50%"
            />
          </div>
          <div
            style="
              font-size: 18px;
              font-weight: 400;
              color: #f4f5f7;
              margin-top: 9px;
              margin-left: 10px;
              cursor: pointer;
            "
          >
            {{ $store.state.user.name }}
          </div>
        </div>

        <el-dropdown-menu slot="dropdown" style="padding: 10px 20px">
          <div
            @click="loginOut()"
            style="
              display: flex;
              flex-direction: row-reverse;
              color: #3082e2;
              font-size: 14px;
              cursor: pointer;
            "
          >
            <div class="logoutbtn">退出登录</div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 200px;
              magrin-top: 10px;
              border-bottom: 2px solid #e4e4e4;
              padding-bottom: 15px;
            "
          >
            <div>
              <img
                v-if="
                  $store.state.user.avatar != '' &&
                  $store.state.user.avatar != null
                "
                :src="$store.state.user.avatar"
                alt=""
                width="60px"
                height="60px"
                style="border-radius: 50%"
              />
              <img
                v-else
                src="https://api.gbservice.cn/storage/uploads/67f630cedc4a08d8cc1e4dcdc0958d7e.png"
                alt=""
                width="60px"
                height="60px"
                style="border-radius: 50%"
              />
            </div>
            <div style="width: 61%">
              <div style="font-size: 16px; font-weight: 400">
                {{ $store.state.user.name }}
              </div>
              <div style="font-size: 14px; margin-top: 5px">
                账号：{{ $store.state.user.username }}
              </div>
              <div style="font-size: 14px">
                企业：{{ $store.state.user.company }}
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
              "
            >
              <div @click="bofang()">
                <img
                  src="https://api.gbservice.cn/storage/uploads/58642fff46b302ca34bfe137724d588c.png"
                  alt=""
                  width="28px"
                />
              </div>
              <div style="font-size: 14px" class="gren" @click="bofang()">
                个人资料
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="navcen">
        <i class="el-icon-s-custom"></i>
        {{ $store.state.user.username }}
      </div>
      <div @click="loginOut" class="navsec">
        <i class="el-icon-switch-button"></i>
        退出
      </div> -->
    </div>
  </div>
</template>
<script>
import { getBiaozhunList } from "../../../api/project";

export default {
  computed: {
    // 获取当前路由的子路由
    mean_list() {
      var routes = {
        children: this.$router.options.routes,
      };
      var route = this.$route.matched;
      for (var i = 0; i < route.length - 1; i++) {
        routes = routes.children.find((e) => e.name == route[i].name);
      }
      // routes = routes.children.find((e) => (e.meta.hidden))
      routes.children = routes.children
        .map((item) => {
          if (!item.meta.hidden) {
            return item;
          }
        })
        .filter((d) => d);
      // console.log(routes.children)
      return routes.children;
    },
    navleft_active() {
      return this.$route.meta.active;
    },
  },

  data() {
    return {
      activeName: "first",
      tableData: [],
      messageList: [],
      page: 1,
      limit: 10,
      shiwTip: true,
    };
  },
  mounted() {
    // this.getRole();
    this.getbiaozhunList();
    this.$store.watch(
      (state) => state.messageList,
      (val) => {
        if (this) {
          this.messageList = val;
          // console.log("messageList", val);
        }
      }
    );
  },
  methods: {
    getbiaozhunList() {
      console.log('56261786')
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.$store.state.token,
        proName: this.proName,
        firstUnit: this.firstUnit,
        zhidingType: this.zhidingType,
        biaozhunType: this.biaozhunType,
      };
      getBiaozhunList(data).then((rec) => {
        this.tableData = rec.data;
        // console.log(this.tableData,'返回数据');
        this.limit = rec.limit;
        this.page = rec.page;
        this.totalCount = rec.totalCount;
        this.totalPage = rec.totalPage;
        this.$store.state.messageList = rec.data;
      });
    },
    getRole() {
      // console.log("role", this.$store.state.user.type);
      return this.$store.state.user.type;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    bofang() {
      if (this.$store.state.user.type == 1) {
        this.$router.push({
          path: "/user/enchildmanage",
        });
      } else if (this.$store.state.user.type == 3) {
        // console.log("3333");
        this.$router.push({
          path: "/user_user/enchildmanage",
        });
      }
    },
    loginOut() {
      this.$store.commit("login_out");
    },

    changeTab(index, item) {
      this.navleft_active = item.meta.active;
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>
<style scoped>
/deep/ .navright .el-dropdown-menu {
  min-width: 350px !important;
}

/deep/ .el-badge__content.is-fixed.is-dot {
  right: 12px;
  top: 4px;
}

/deep/ .el-tabs__item {
  height: auto !important;
}

/deep/ .el-tabs__active-bar {
  background-color: #f56c6c;
}

/deep/ .el-tabs__item.is-active {
  color: #f56c6c;
}
/deep/.el-popover {
  right: 49px;
  top: 60px;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
  height: 40px;
  line-height: 40px;
}

.linkto > span:hover {
  color: #84b5ec;
}

.toling {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.logoutbtn:hover {
  color: #479dfd;
}

.gren:hover {
  color: #3082e2;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.cointer {
  width: 100%;
  height: 60px;
  background-color: #3082e2;
  box-shadow: 0px 4px 4px 0px rgba(169, 169, 169, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navleft {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #f4f5f7;
}

.navright {
  display: flex;
  flex-direction: row;
  margin-right: 26px;
}

.navleft .nlfir {
  width: 100px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlfir_active {
  width: 100px;
  text-align: center;
  border-bottom: 4px solid #479dfd;
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlsec {
  width: 70px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navleft .nlsec_active {
  width: 70px;
  text-align: center;
  border-bottom: 4px solid #479dfd;
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navright .navcen {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #f4f5f7;
  margin-right: 20px;
}

.navright .navsec {
  color: #9c9fa3;
  margin-right: 38px;
  /* font-weight: bold; */
  cursor: pointer;
}

.borderstyle {
  border: 1px solid;
}

/* .tohver {
  height: 41px;
}
.tohver:hover {
  height: 41px;
  background: #5f98db;
  border-radius: 15px;
  padding: 0 10px;
} */
</style>
