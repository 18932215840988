<template>
  <div >
    <div class="innav">
      <energy-nav />
    </div>
    <div class="cointener">
      <div class="ininside">
      <energy-inside :showSide="showSide" @changeShowSide="changeShowSide" />
    </div>
    <div :class="showSide?'inright':'inrightBig'" >
      <div class="inconent">
        <router-view></router-view>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import energyInside from "../EngeryEvaluation/components/energyInside.vue";
import energyNav from "../EngeryEvaluation/components/energyNav.vue";

export default {
  components: { energyInside, energyNav },
  data() {
    return {
      active: 0,
      showSide: true
    };
  },

  methods: {
    changeUrl() {},
    changeShowSide(value){
      this.showSide = value;
    },
  },
};
</script>
<style scoped>
.cointener {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0;
  margin: 0;
  background-color: #fafafa;
}

.ininside {
  padding-right: 5px;
  position: fixed;
}

.innav {
  /* margin-left: 10px; */
  /* width: 99% */
}

.inright {
  width: calc(100% - 100px);
  padding-left: 175px;
  transition: all .2s;
  overflow-x: hidden;
}
.inrightBig {
  width: 100%;
  padding-left: 100px;
  transition: all .2s;
  overflow-x: hidden;
}

.inconent {
  padding: 10px 20px;
  height: 90vh;
}
</style>
