<template>
  <div class="coninter" :style="showSide ? '' : 'width:75px;transition: all .5s'">
    <div v-for="item in mean_list" :key="item.meta.active" @click="
      sidekuai_active = item.meta.active;
    $router.push({ name: item.name });
    " :class="sidekuai_active == item.meta.active ? 'sidekuai_active' : 'sidekuai'
  " :style="item.meta.active
    ? showSide
      ? ''
      : 'background-color: unset; width: unset;transition: all .5s'
    : ''
    ">
      <div class="skimg">
        <img v-if="sidekuai_active == item.meta.active" :src="item.meta.icon2" alt="" />
        <img v-if="sidekuai_active != item.meta.active" :src="item.meta.icon" alt="" />
      </div>
      <div v-if="showSide" class="sktext">{{ item.meta.name }}</div>
    </div>

    <div @click="changeShowSide(false)" v-if="showSide" class="toshou">
      <i class="el-icon-s-fold" style="color: #3082e2; font-size: 40px"></i>
    </div>
    <div @click="changeShowSide(true)" v-else class="toshou">
      <i class="el-icon-s-unfold" style="color: #3082e2; font-size: 40px"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showSide: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sidekuai_active: this.$route.meta.parent_active,
    };
  },

  computed: {
    // 获取当前路由的子路由
    mean_list() {
      var routes = {
        children: this.$router.options.routes,
      };

      routes = routes.children.find((e) => e.name == "energyIndex");
      // console.log(routes,'----------------');
      // console.log(routes.children)
      let list = routes.children.map((item) => {
        for (let i = 0; i < this.$store.state.role.length; i++) {
          if (!item.meta || !item.meta.role) {
            return item;
          }
          if (item.meta.role.indexOf(this.$store.state.role[i]) != -1) {
            return item;
          }
        }
      });
      // console.log(list.filter(d=>d),'----------------');
      return list.filter((d) => d);
    },
  },

  mounted() { },

  methods: {
    changeShowSide(value) {
      this.$emit("changeShowSide", value);
    },
    change_url() { },
  },
};
</script>
<style lang="scss" scoped>
.coninter {
  width: 186px;
  height: 100vh;
  background: #ffffff;
  padding-top: 30px;
  transition: all 0.2s;
  position: relative;
}

.sidekuai {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  // width: 167px;
  height: 48px;
  margin-bottom: 20px;
  margin-left: 30px;
  cursor: pointer;
  white-space:nowrap;

  .sktext {
    color: #666666;
    font-size: 18px;
    margin-left: 15px;
  }

  .skimg {
    width: 12px;
    height: 12px;
    margin-bottom: 8px;
  }

  .skimg>img {
    // width: 100%;
    // height: 100%;
    vertical-align:middle;
    object-fit:contain;
  }
}

.sidekuai:hover .sktext {
  color: #3082e2;
}

.sidekuai_active {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  height: 48px;
  margin-left: 14px;
  padding-right: 12px;
  width: 160px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #c2deff;
  border-radius: 10px 0 0 10px;
  position: relative;
  white-space:nowrap;

  .sktext {
    color: #282828;
    font-size: 18px;
    margin-left: 10px;
  }

  .skimg {
    width: 48px;
    height: 48px;
    background: #509bfd;
    border-radius: 12px;
    text-align:center;
    line-height:42px;
  }

  .skimg>img {
    // width: 100%;
    // height: 100%;
    vertical-align: middle;
    object-fit: contain;
  }
}

.toshou {
  position: absolute;
  bottom: 137px;
  right: 20px;
  cursor: pointer;
}

.definedIcon {
  width: 48px;
  height: 48px;
  background: #509bfd;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  .download_user {
    font-size: 30px;
  }
}
</style>
