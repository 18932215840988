var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cointer"},[_c('div',[_c('div',{staticClass:"navleft"},[_vm._v(" 江苏省工程建设地方标准编制系统 "),(_vm.$store.state.role[0] == 1)?_c('div',{staticStyle:{"margin-left":"15px","font-size":"14px","margin-top":"9px"}},[_vm._v(" 管理版 ")]):_vm._e(),(_vm.$store.state.role[0] == 3)?_c('div',{staticStyle:{"margin-left":"15px","font-size":"14px","margin-top":"9px"}},[_vm._v(" 企业版 ")]):_vm._e()])]),_c('div',{staticClass:"navright"},[(_vm.getRole() == 3)?_c('el-popover',{staticStyle:{"margin-right":"20px","margin-top":"7px"},attrs:{"placement":"bottom","width":"400","trigger":"click"},model:{value:(_vm.shiwTip),callback:function ($$v) {_vm.shiwTip=$$v},expression:"shiwTip"}},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"提醒","name":"first"}},[(
              _vm.tableData.findIndex(
                function (item) { return item.status == 6 ||
                  item.status == 9 ||
                  item.status == 11 ||
                  item.status == 13; }
              ) != -1
            )?_c('div',_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index},[(
                  item.status == 6 ||
                  item.status == 9 ||
                  item.status == 11 ||
                  item.status == 13
                )?_c('div',{staticStyle:{"border-bottom":"1px solid #e4e7ed"}},[_vm._v(" 状态为"+_vm._s((item.status == 6) ? "待提交初稿" : (item.status == 9) ? "待提交征求意见稿" : (item.status == 11) ? "待提交送审稿及材料" : (item.status == 13) ? "待提交报批表" : "其他状态")+"的 请尽快提交《 "),_c('router-link',{staticClass:"linkto",attrs:{"to":'/user_project/chugaoForqiyeDetail?id=' + item.id}},[_c('span',[_vm._v(_vm._s(item.proName))])]),_vm._v(" 》标准的资料，谢谢! ")],1):_vm._e()])}),0):_c('div',[_vm._v("暂无信息")])]),_c('el-tab-pane',{attrs:{"label":"","name":"second"}})],1),_c('span',{staticClass:"el-dropdown-link",attrs:{"slot":"reference"},slot:"reference"},[(
            _vm.tableData.status == 6 ||
            _vm.tableData.status == 9 ||
            _vm.tableData.status == 11 ||
            _vm.tableData.status == 13
          )?_c('el-badge',{attrs:{"is-dot":""}},[_c('i',{staticClass:"el-icon-message-solid",staticStyle:{"color":"red","font-size":"32px"}})]):_c('i',{staticClass:"el-icon-message-solid",staticStyle:{"color":"red","font-size":"32px"}})],1)],1):_vm._e(),_c('el-dropdown',[_c('div',{staticClass:"el-dropdown-link tohver"},[_c('div',[(
              _vm.$store.state.user.avatar != '' &&
              _vm.$store.state.user.avatar != null
            )?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.$store.state.user.avatar,"alt":"","width":"40px","height":"40px"}}):_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"https://api.gbservice.cn/storage/uploads/699ab4d283acc595369836beb8082607.png","alt":"","width":"40px","height":"40px"}})]),_c('div',{staticStyle:{"font-size":"18px","font-weight":"400","color":"#f4f5f7","margin-top":"9px","margin-left":"10px","cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" ")])]),_c('el-dropdown-menu',{staticStyle:{"padding":"10px 20px"},attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row-reverse","color":"#3082e2","font-size":"14px","cursor":"pointer"},on:{"click":function($event){return _vm.loginOut()}}},[_c('div',{staticClass:"logoutbtn"},[_vm._v("退出登录")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"200px","magrin-top":"10px","border-bottom":"2px solid #e4e4e4","padding-bottom":"15px"}},[_c('div',[(
                _vm.$store.state.user.avatar != '' &&
                _vm.$store.state.user.avatar != null
              )?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.$store.state.user.avatar,"alt":"","width":"60px","height":"60px"}}):_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"https://api.gbservice.cn/storage/uploads/67f630cedc4a08d8cc1e4dcdc0958d7e.png","alt":"","width":"60px","height":"60px"}})]),_c('div',{staticStyle:{"width":"61%"}},[_c('div',{staticStyle:{"font-size":"16px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" ")]),_c('div',{staticStyle:{"font-size":"14px","margin-top":"5px"}},[_vm._v(" 账号："+_vm._s(_vm.$store.state.user.username)+" ")]),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" 企业："+_vm._s(_vm.$store.state.user.company)+" ")])])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"10px"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","cursor":"pointer"}},[_c('div',{on:{"click":function($event){return _vm.bofang()}}},[_c('img',{attrs:{"src":"https://api.gbservice.cn/storage/uploads/58642fff46b302ca34bfe137724d588c.png","alt":"","width":"28px"}})]),_c('div',{staticClass:"gren",staticStyle:{"font-size":"14px"},on:{"click":function($event){return _vm.bofang()}}},[_vm._v(" 个人资料 ")])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }